import { Route, Routes } from "react-router-dom";
import { OperationsPage } from "./";
import { ReservationPage } from "./reservation";
import { MenuPage } from "./menu";
import { BranchPage } from "./branch";
import { ProductPage } from "./product";
import { OrderPage } from "./order";
import { MealPage } from "./meal";

const Router = () => (
    <Routes>
        <Route index element={ <OperationsPage /> } />    
        <Route path="reservation/*" element={ <ReservationPage /> } />
        <Route path="menu/*" element={ <MenuPage /> } />
        <Route path="branch/*" element={ <BranchPage /> } />
        <Route path="product/*" element={ <ProductPage /> } />
        <Route path="order/*" element={ <OrderPage /> } />
        <Route path="meal/*" element={ <MealPage /> } />
    </Routes>  
);

export default Router;
