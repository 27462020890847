let tr = {
    translation: {
        "New": "Yeni",
        "Filter": "Filtrele",
        "Profile": "Profil",
        "Logout": "Çıkış Yap",
        "Dashboard": "Ana Sayfa",
        "Operations": "İşlemler",
        "Branches": "Mağazalar",
        "Menus": "Menüler",
        "Products": "Ürünler",
        "Orders": "Siparişler",
        "Reservations": "Rezervasyonlar",
        "Meals": "Öğünler",
        "Account Settings": "Hesap Ayarları",
        "Management Top": "Management Top",
        "News": "News",
        "Contact": "Contact",
        "Sales of the Day": "Sales of the Day",
        "Total Sales of the Month": "Total Sales of the Month",
        "Target Sales": "Target Sales",
        "Achievement Rate": "Achievement Rate",
        "Food Cost": "Food Cost",
        "Labor Cost": "Labor Cost",
        "F/L": "F/L",
        "Sales Prediction of the Month": "Sales Prediction of the Month",
        "Target Sales Prediction": "Target Sales Prediction",
        "Total": "Total"
    }
};


export default tr;
