import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        path: "/membership",
        breadcrumbName: "Account Settings"
    },
    {
        path: "/role",
        breadcrumbName: "Roles"
    }
];

const Columns = [
    {
        title: "Role",
        render: (_, record) => (
            <>
                <div>{ record?.name }</div>
                <div className="text-muted">{ record?.description }</div>
            </>
        )
    }
];
const Path = "/membership/role";
const Url = "/roles";
const ListUrl = `${Url}/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

