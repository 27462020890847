import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ProfileMenuItems } from "@constant";

import { useAuth, useLayout } from "@context";

import { Avatar, Button, Col, Dropdown, Layout, Menu, Row, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const { Header:AntdHeader } = Layout;

const Header = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { authState, removeSession } = useAuth();
    const { collapsed, toggleCollapsed } = useLayout();
    const [selectedLanguage, setLanguage] = useState(i18n.language);
    const color = "#1990ff";
    {console.log(selectedLanguage);}
    const languages = [
        { key: "jp", label: "日本語" },
        { key: "en", label: "English" }
    ];

    const handleLogout = async () => {
      
        await removeSession();
        window.location.href = "/auth/login";
        return;
        
    };

    const handleLanguageClick = async (item) => {
        i18n.changeLanguage(item.key);
        setLanguage(item.key);
    };

    return (
        <AntdHeader className="site-layout-background" style={ { background: "#fceef0" } }>
            <Row type="flex" align="middle">
                <Col justify="start" span={ 3 }>
                    { collapsed ? 
                        <MenuUnfoldOutlined style={ { color:"#fff", fontSize: "20px" } } 
                            onClick={ toggleCollapsed }/> : 
                        <MenuFoldOutlined style={ { color:"#fff", fontSize: "20px" } }
                            onClick={ toggleCollapsed }/> }
                </Col>
                <Col span={ 19 } ></Col>
                <Col span={ 1 } style={ { textAlign: "right" } }>
                    <Dropdown overlay={  <Menu items={ languages } onClick={ handleLanguageClick } /> }>
                        <Button type="text" style={ {  verticalAlign: "top" } }>
                            <Avatar src={ `/img/flags/${selectedLanguage}.png` }/>
                        </Button>
                    </Dropdown>
                </Col>
                <Col span={ 1 } style={ { textAlign: "right" } }>

                    <Tooltip title="ログアウト">
                        <Button
                            type="text"
                            onClick={ handleLogout }
                            icon={ <LogoutOutlined style={ { fontSize: "20px" , verticalAlign: "middle" } } /> } // İkonun boyutunu ayarla
                        >
                          
                        </Button>
                    </Tooltip>
     
                </Col>
            </Row>
        </AntdHeader>
    );
};
export default Header;
