import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        path: "/product",
        breadcrumbName: "Products"
    }
];

const Columns = [
    {
        title: "Product",
        render: (_, record) => (
            <>
                <div>{ record?.name }</div>
                <div>{ record?.slug }</div>
                <div>{ record?.sale_price }</div>
                <div>{ record?.cost }</div>
            </>
        )
    }
];
const Path = "/operations/product";
const Url = "/products";
const ListUrl = `${Url}/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

