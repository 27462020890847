import React, { createContext, useContext, useState } from "react";

const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const toggleCollapsed = () => setCollapsed(!collapsed);
    const value = {
        collapsed,
        loading,
        setLoading,
        toggleCollapsed
    };
    return <LayoutContext.Provider value={ value }>{ children }</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
    return useContext(LayoutContext);
}
