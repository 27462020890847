import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        path: "/reservation",
        breadcrumbName: "Reservations"
    }
];

const Columns = [
    {
        title: "Reservation",
        render: (_, record) => (
            <>
                <div>{ record.branch ? record?.branch.name : ''  }</div>
                <div>{ record.order ? record?.order.order_key : ''  }</div>
                <div>{ record?.reservation_date }</div>
                <div>{ record?.number_of_customer }</div>

            </>
        )
    }
];
const Path = "/operations/reservation";
const Url = "/reservations";
const ListUrl = `${Url}/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

