/* eslint-disable no-restricted-imports */
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { useFilter } from "@context";
import { Button, Col, Dropdown, Form, Input, InputNumber, Row, Space } from "antd";
import { isEqual } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FilterMenu from "./FilterMenu";





const prepareFilter = (queryParams, values) => {
    const {
        branch_ids,
        company_ids,
        date,
        sales_achievement_rate,
        f_l_cost_rate,
        f_l_rate_is_over,
        achievement_rate_is_over
        
    } = values;
  
    const newQueryParams = { ...queryParams };
    newQueryParams.branch_ids = [];
    if (branch_ids) {
        newQueryParams.branch_ids = branch_ids;
    }
    newQueryParams.company_ids = [];
    if (company_ids) {
        newQueryParams.company_ids = company_ids;
    }
  
    // Date format might need adjustment based on your backend requirements
    if (date !== undefined) {
        newQueryParams.date = moment(date).format("YYYY-MM-DD");
    }
  
    if (sales_achievement_rate !== null ) {
        newQueryParams.sales_achievement_rate = sales_achievement_rate;
    }
  
    if (f_l_cost_rate !== null) {
        newQueryParams.f_l_cost_rate = f_l_cost_rate;
    }
    if (achievement_rate_is_over !== null) {
        newQueryParams.achievement_rate_is_over = achievement_rate_is_over;
    }
    if (f_l_rate_is_over !== null) {
        newQueryParams.f_l_rate_is_over = f_l_rate_is_over;
    }
  
    return newQueryParams;
};

const Filter = () => {
    const { queryParams, setQueryParams } = useFilter();
    const push = useNavigate();
    const { t } = useTranslation();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => {
        push("add");
    };
    const handleSubmit = (values) => applyFilter({ ...queryParams.filter, ...values });

    const _handleInputChange = (changedValues) => {
        // changedValues nesnesi, değişiklik yapılan alanların adlarını içerir
        const updatedValues = { ...queryParams, ...changedValues };

        // Güncellenmiş değerleri kullanarak filtreleme işlemini gerçekleştir
        applyFilter(updatedValues);
    };
    
    const filterMenu = () => <FilterMenu _handleInputChange={ _handleInputChange } />;

    return (
        <Form
            layout="horizontal"
            requiredMark={ "Required" }
            onValuesChange={ _handleInputChange }
        >
            <Row >
                <FilterMenu _handleInputChange={ _handleInputChange } />
            </Row>
        </Form>
    );
};
export default Filter;
