import { Spinner } from "@components";
import { MasterProvider } from "@context";
import { Default } from "@routes";

import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

const App = () => (
    <BrowserRouter>
        <MasterProvider>
            <Suspense fallback={ <Spinner /> }>
                <Default />
            </Suspense>
        </MasterProvider>
    </BrowserRouter>
);

export default App;
