/* eslint-disable no-restricted-imports */
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { useFilter } from "@context";
import { Button, Col, Dropdown, Form, Input, Row, Space } from "antd";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import FilterMenu from "./FilterMenu";

const prepareFilter = (queryParams, values) => {
    const { active, name } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (active) filter.active = active;
    if (name) filter.name = name;
    newQueryParams.filter = filter;
    return newQueryParams;
};

const Filter = () => {
    const { queryParams, setQueryParams } = useFilter();
    const push = useNavigate();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => {
        push("add");
    };
    const handleSubmit = (values) => applyFilter({ ...queryParams.filter, ...values });

    const _handleInputChange = (event) => applyFilter({ ...queryParams.filter, name:event.target.value });
    
    const filterMenu = () => <FilterMenu handleSubmit={ handleSubmit } />;

    return (
        <Form
            layout="vertical"
            requiredMark={ "Required" }
        >
            <Row gutter={ 24 }>
                <Col span={ 6 }>
                    <div style={ { "marginBottom": "10px" } }>
                        <Form.Item name="searchText" required>
                            <Input 
                                onChange={ _handleInputChange } 
                                placeholder="Keyword" 
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col style={ { marginLeft: "auto" } }>
                    <Space>
                        <Button size="small" shape="round" 
                            type="dashed" onClick={ handleAdd } block icon={ <PlusOutlined /> }>
                            New
                        </Button>
                        <Dropdown overlay={ filterMenu } trigger={ ["click"] }>
                            <Button size="small" shape="round" type="primary">
                                Filter
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
export default Filter;
