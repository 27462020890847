import { get, post, put } from "@util";

import { Button, Drawer, Form, Input, Select, TimePicker, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [menus, setMenus] = useState(undefined);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await post(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            result.opening_hour = moment(result.opening_hour, "HH:mm:ss");
            result.closing_hour = moment(result.closing_hour, "HH:mm:ss");
            setData(result);
        }
    };

    const getMenus = async () => {
        const { result, success, message } = await get("/menus/");
        if (!success) {
            return;
        }
        let menus = result.map(menu => {return { label: menu.name, value: menu.id };});
        setMenus(menus);
    };
   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        values.opening_hour = values.opening_hour.format(values.opening_hour._f);
        values.closing_hour = values.closing_hour.format(values.closing_hour._f);
        console.log(values);
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 500 }
            title= { primaryKey ? "Edit Branch" : "Add Branch" }
            visible={ visible }
            onClose={ onClose }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={ [{ required: true, message: "Branch name required!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Opening Hour"
                    name="opening_hour"
                >
                    <TimePicker style={ { width: "100%" } } defaultOpenValue={ moment("09:00:00", "HH:mm:ss") } minuteStep={ 15 } secondStep={ 10 } />
                </Form.Item>
                <Form.Item
                    label="Clossing Hour"
                    name="closing_hour"
                >
                    <TimePicker style={ { width: "100%" } } defaultOpenValue={ moment("22:00:00", "HH:mm:ss") } minuteStep={ 15 } secondStep={ 10 } />
                </Form.Item>
                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
