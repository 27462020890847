import { get, post, put } from "@util";

import { Button, Drawer, Form, Input, InputNumber, notification, Select, DatePicker, Space, TimePicker } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";
import moment from "moment";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [branch, setBranch] = useState(undefined)
    const [meal, setMeal] = useState(undefined)
    const [staff, setStaff] = useState(undefined)
    const [products, setProducts] = useState(undefined)
    const paymentTypes = [
        {
            label: "Cash",
            value: 0
        },
        {
            label: "Card",
            value: 1
        },
        {
            label: "Other",
            value: 2
        }
    ]
    const orderTypes = [
        {
            label: "In Branch",
            value: 0
        }, 
        {
            label: "Take Out",
            value: 1
        }
    ]


    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            console.log(result)
            result.ordered_at = moment(result.ordered_at)
            setData(result);
        }
    };

    const getBranch = async () => {
        const { result, success, message } = await get(`/branches/`);
        if (!success) {
            return;
        }
        let branches = result.map(branch => { return { label: branch.name, value: branch.id } })
        setBranch(branches)
    };


    const handleBranchChange = async (value) => {
        console.log("value", value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
        const { result, success, message } = await get(`/branches/${value}/meals/`);
        if (!success) {
            return;
        }
        let meals = result.map(meal => { return { label: meal.name, value: meal.id } })
        setMeal(meals)
    };

    const getProducts = async () => {
        const { result, success, message } = await get(`/products/`);
        if (!success) {
            return;
        }
        let products = result.map(product => { return { label: product.name, value: product.id, price: product.sale_price } })
        setProducts(products)
    };


    const getStaff = async () => {
        const { result, success, message } = await get(`/staffs/`);
        if (!success) {
            return;
        }
        let staffs = result.map(staff => { return { label: staff.name + " " + staff.surname, value: staff.id } })
        // let staffs = formatResult(result)
        setStaff(staffs)
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getBranch();
        getProducts();
        getStaff();
    }, []);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        values.ordered_at = values.ordered_at.format(values.ordered_at._f);
        if (typeof values.order_type == 'object') {
            values.order_type = values.order_type.value
        }
        if (typeof values.payment_type == 'object') {
            values.payment_type = values.payment_type.value
        }
        values.payment_type = Number(values.payment_type)
        values.order_type = Number(values.order_type)
        console.log(values);
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) :
            await put(`${Url}/${primaryKey}`, values);
        if (!success) {
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={500}
            title={primaryKey ? "Edit Order" : "Add Order"}
            visible={visible}
            onClose={onClose}
        >
            <Form
                form={form}
                layout="vertical"
                requiredMark={"Required"}
                onFinish={handleSave}
                initialValues={{
                    ["payment_type"]: {
                        value: 0,
                        label: 'Cash',
                    },
                    ["order_type"]: {
                        value: 0,
                        label: 'In Branch'
                    }
                }}
            >
                <Form.Item
                    label="Branch"
                    name="branch_id"
                    rules={[{ required: true, message: "Order branch required!" }]}
                >
                    <Select options={branch} onChange={handleBranchChange} />
                </Form.Item>
                <Form.Item
                    label="Meal"
                    name="meal_id"
                    rules={[{ required: true, message: "Order meal required!" }]}
                >
                    <Select options={meal} />
                </Form.Item>
                <Form.Item
                    label="Staff"
                    name="staff_id"
                    rules={[{ required: true, message: "Order staff required!" }]}
                >
                    <Select options={staff} />
                </Form.Item>
                <Form.Item
                    label="Ordered At"
                    name="ordered_at"
                >
                    <DatePicker
                        showTime={true}
                        // format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item
                    label="Payment Type"
                    name="payment_type"
                >
                    <Select
                        options={paymentTypes}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Number Of Customer"
                    name="number_of_customer"
                    rules={[{ required: true, message: "Order number of customer required!" }]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    label="Order Type"
                    name="order_type"
                >
                    <Select
                        options={orderTypes}
                    >
                    </Select>
                </Form.Item>
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'product_id']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Product is Require',
                                            },
                                        ]}
                                    >
                                        <Select style={{ width: "100%" }} placeholder="Product" options={products} />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'quantity']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Quantity is Require',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: "100%" }} placeholder="Quantity" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
