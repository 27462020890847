import { get, post, put } from "@util";

import { Button, Drawer, Form, Input, InputNumber, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id: primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [menu, setMenu] = useState(undefined)
    const product_types = [
        {
            label: "food", value: 0
        },
        {
            label: "drink", value: 1
        },
        {
            label: "consumable", value: 2
        }
    ]

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    const getMenu = async () => {
        const { result, success, message } = await get(`/menus/`);
        console.log(result)
        if (!success) {
            return;
        }
        let menus = result.map(r => {return {label: r.name, value: r.id}})
        console.log("menus", menus)
        setMenu(menus)
    }


    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getMenu();
    }, []);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    };

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) :
            await put(`${Url}/${primaryKey}`, values);
        if (!success) {
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={500}
            title={primaryKey ? "Edit Product" : "Add Product"}
            visible={visible}
            onClose={onClose}
        >
            <Form
                form={form}
                layout="vertical"
                requiredMark={"Required"}
                onFinish={handleSave}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Product name required!" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Sale Price"
                    name="sale_price"
                >
                    <InputNumber style={{width: "100%"}} />
                </Form.Item>
                <Form.Item
                    label="Cost"
                    name="cost"
                >
                    <InputNumber style={{width: "100%"}} />
                </Form.Item>
                <Form.Item
                    label="Menu"
                    name="menu_id"
                >
                    <Select options={menu} />
                </Form.Item>
                <Form.Item
                    label="Product Type"
                    name="product_type"
                >
                    <Select options={product_types} defaultValue={0} />
                </Form.Item>

                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
