import { statusOptions } from "@constant";
import { formatResult } from "@helper";
import { get, post, put } from "@util";
import { Button, Checkbox, Col, DatePicker, Form, InputNumber, Row, Select, Space, notification  } from "antd";
import moment from "moment"; 
import { useEffect, useState } from "react";
import "moment/locale/ja";
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";



moment.updateLocale("ja", {
    week: {
        dow: 1 // Pazartesi günü için date offset
    }
});

const FilterMenu = ({ _handleInputChange }) => {
    const [companies, setCompanies] = useState(undefined);
    const [branches, setBranches] = useState(undefined);
    const { t, i18n } = useTranslation();
      
    const getCompanies = async () => {
        let filter = { "active": true };
        const { result, success, message } = await get("/companies/?pageNumber=0&pageSize=0&sortField=id&sortOrder=desc&filter={\"active\": true}");
        if (!success) {
            notification.error({
                description: message,
                message: "注意",
    
                placement: "top"
            });
            return;
        }
        const companies_ = formatResult(result);
        setCompanies(companies_);
    
    };

    const getBranches = async (company_id) => {
        let filter = { "active": true };
    
        if (company_id !== null) {
            filter["company_id"] = company_id ;
        }
    
        const queryString = `pageNumber=0&pageSize=0&sortField=id&sortOrder=desc&filter=${JSON.stringify(filter)}`;
        
        const { result, success, message } = await get(`/branches/manager/list?${queryString}`);
        if (!success) {
            notification.error({
                description: message,
                message: "注意",
    
                placement: "top"
            });
            return;
        }
        const branches_ = formatResult(result);
        setBranches(branches_);
    
    };
    // const getDistricts = async (county_Id) => {
    //     const { result, success, message } = await post(`/address/back-office/districts/list/${county_Id}`, 
    //         { filter: { active: 1 }, pageSize: 10000 });
    //     if (!success) {
    //         toast.push(<Notification type="danger" title={ message } />, {
    //             placement: "top-center"
    //         });
    //         return;
    //     }
        
    //     const districts_ = formatResult(result);
    //     setDistricts(districts_);
    
    // };

    useEffect(() => {
        getCompanies();
        getBranches();
    }, []);
    return(<Form
        layout="horizontal"
        requiredMark="Required"
        style={ { width: "100%", marginBottom: "40px" } }
        initialValues={ {
            branch_ids: [],
            company_ids: [],
            date: moment(),
            f_l_cost_rate: 50,
            sales_achievement_rate: 50,
            achievement_rate_is_over: true,
            f_l_rate_is_over: true
        } }
        onValuesChange={ _handleInputChange }
    >
        <div className="filterBox">
            <Row gutter={ 40 }>
                <Col>
                    <Form.Item
                        label={ t("Date") }
                        name="date"
                        required
                        tooltip={ t("Selected Date") }
                        style={ { width: "100%" } }
                    >
                        <DatePicker size="small" style={ { width: "100%" } } locale={ locale } allowClear={ false } />
                    </Form.Item>
                    <Form.Item label={ t("Restaurant Brand") } name="company_ids" style={ { width: "100%" } }>
                        <Select
                            size="small"
                            placeholder={ t("All Brand") }
                            onChange={ async (option) => {
                                await getBranches(option);
                            } }
                            mode="multiple"
                            options={ companies }
                            allowClear
                        />
                    </Form.Item>
                    <Space>
                        <Form.Item
                            label={ t("Sales Achievement Rate Alert") }
                            name="sales_achievement_rate"
                            tooltip={ t("Sales Achievement Rate") }
                            style={ { width: "100%" } }
                        >
                            <InputNumber size="small" />
                        
                        </Form.Item>
                        <Form.Item name="achievement_rate_is_over" style={ { width: "100%" } }>
                            <Select
                                size="small"
                                options={ [
                                    { label: t("Over"), value: true },
                                    { label: t("Under"), value: false }
                                ] }
                            />
                        </Form.Item>
                    </Space>
                </Col>
                
                <Col>
                    <Form.Item style={ { width: "100%" } }></Form.Item>
                    <Form.Item label={ t("Branch") } name="branch_ids" style={ { width: "100%" } }>
                        <Select size="small" placeholder={ t("All Branch") } mode="multiple" options={ branches } allowClear />
                    </Form.Item>

                    <Space>
                        <Form.Item
                            label={ t("F/L Cost Rate Alert") }
                            name="f_l_cost_rate"
                            tooltip={ t("F L Cost Rate") }
                            style={ { width: "100%" } }
                        >
                            <InputNumber size="small" />
                        </Form.Item>
                        <Form.Item name="f_l_rate_is_over" style={ { width: "100%" } }>
                            <Select
                                size="small"
                                options={ [
                                    { label: t("Over"), value: true },
                                    { label: t("Under"), value: false }
                                ] }
                            />
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </div>
    </Form>);
    
};
export default FilterMenu;
