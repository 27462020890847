import { MasterLayout } from "@components";
import { useAuth } from "@context";
import { DashboardPage, MemberShipRouter } from "@routes";
import { get } from "@util";

import { notification } from "antd";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { OperationsRouter } from "./operations";

const AppRenderer = () => {
    const navigate = useNavigate();
    const { createSession, removeSession } = useAuth();

    useEffect(() => {
        const handleAuth = async () => {
            const { success, message, account, tokens } = await get("/authenticate/me");
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                removeSession();
                window.location.href = "/auth/login";
                // navigate("/auth/login");
                return;
            }
            let user = account;
            createSession({ tokens, user });
        };
        handleAuth();
    }, []);


    return (
        <MasterLayout>
            <Routes>
                <Route path="dashboard" element={ <DashboardPage /> } />
                <Route path="membership/*" element={ <MemberShipRouter /> } />
                <Route path="operations/*" element={ <OperationsRouter /> } />
            </Routes>
        </MasterLayout> 
    );
};

export default AppRenderer;
