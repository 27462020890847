let en = {
    translation: {
        "New": "New",
        "Filter": "Filter",
        "Profile": "Profile",
        "Logout": "Logout",
        "Dashboard": "Dashboard",
        "Operations": "Operation",
        "Branches": "Branches",
        "Restaurant": "Restaurant",
        "Branch": "Branch",
        "Menus": "Menus",
        "Products": "Products",
        "Orders": "Orders",
        "Reservations": "Reservations",
        "Meals": "Meals",
        "Account Settings": "Account Settings",
        "Management Top": "Management Top",
        "News": "News",
        "Contact": "Contact",
        "Sales of the Day": "Sales of the Day",
        "Total Sales of the Month": "Total Sales of the Month",
        "Target Sales": "Target Sales",
        "Achievement Rate": "Achievement Rate",
        "Food Cost": "Food Cost",
        "Labor Cost": "Labor Cost",
        "F/L": "F / L",
        "Sales Prediction of the Month": "Sales Prediction of the Month",
        "Target Sales Prediction": "Target Sales Prediction",
        "Total": "Total",
        "Date" : "Date",
        "Restaurant Brand": "Restaurant Brand",
        "All Brand":  "All Brand",
        "All Branch": "All Branch",
        "Sales Achievement Rate Alert": "Sales Achievement Rate Alert",
        "F/L Cost Rate Alert": "F/L Cost Rate Alert",
        "Over": "Over",
        "Under": "Under",
        "Sales Achievement Rate": "Sales Achievement Rate",
        "F L Cost Rate": "F L Cost Rate",
        "Selected Date": "Selected Date",
        "Refresh": "Refresh"
    }
};

export default en;
