let ja = {
    translation: {
        "New": "新着",
        "Filter": "フィルター",
        "Profile": "プロフィール",
        "Logout": "サインアウト",
        "Dashboard": "ダッシュボード",
        "Operations": "操作",
        "Branches": "飲食店",
        "Menus": "メニュー",
        "Products": "製品",
        "Orders": "注文",
        "Restaurant": "店名",
        "Branch": "支店名",
        "Reservations": "予約",
        "Meals": "食事",
        "Account Settings": "アカウント設定",
        "Management Top": "管理トップ",
        "News": "お知らせ",
        "Contact": "お問合せ",
        "Sales of the Day": "当日売上",
        "Total Sales of the Month": "当月累計",
        "Target Sales": "目標売上",
        "Achievement Rate": "達成率",
        "Food Cost": "F",
        "Labor Cost": "L",
        "F/L": "F / L",
        "Sales Prediction of the Month": "着地予想",
        "Target Sales Prediction": "予測達成率",
        "Total": "合計",
        "Date" : "年月日",
        "Restaurant Brand": "店舗ブランド",
        "All Brand":  "すべての店舗ブランド",
        "All Branch": "すべての支店",
        "Sales Achievement Rate Alert": "売上達成率",
        "F/L Cost Rate Alert": "F/L率",
        "Over": "以上",
        "Under": "以下",
        "Sales Achievement Rate": "売上達成率\n当月累計が目標売上の何％に達しているかを表します。\n（達成率＝当月累計÷目標売上）",
        "F L Cost Rate": "F/L率\n売上高に占める食材原価と人件費比率がどれくらいかを\n表します。（F/L率＝(F:仕入れ＋L:人件費)÷当月累計)",
        "Selected Date":"日付を選択すると、当日の売上とこの日までの当月累計\nが表示され、各種指標値が計算されます。",
        "Refresh": "更新"
    }
};

export default ja;
