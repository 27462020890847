export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
];
export const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
    sortField: "id",
    sortOrder: "desc",
    filter: {}
};
export const statusOptions = [
    { value: "", label: "All" },
    { value: true, label: "Aktive" },
    { value: false, label: "Inactive" }
];
export const sizes = [
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "10", value: 10 }
];

export const infoFormItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
};
  

