import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        path: "/membership",
        breadcrumbName: "Account Settings"
    },
    {
        path: "/user",
        breadcrumbName: "Users"
    }
];

const Columns = [
    {
        title: "User",
        render: (_, record) => (
            <>
                <div>{ record?.email }</div>
            </>
        )
    }
];
const Path = "/membership/user";
const Url = "/accounts";
const ListUrl = `${Url}/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

