import { statusOptions } from "@constant";
import { Button, Form, Row, Select } from "antd";

const FilterMenu = ({ handleSubmit }) => (
    <Form
        layout="vertical"
        requiredMark={ "Required" }
        initialValues={ { active:statusOptions[1].value } }
        onFinish={ handleSubmit }
    >
        <div className="filterBox">
            <Row>
                <div className="filterTitle">Filter Options</div>
            </Row>
            <Row>
                <Form.Item label="Durum" name="active" 
                    required tooltip="Status" style={ { width: "100%" } }>
                    <Select size="small" options={ statusOptions } />
                </Form.Item>
            </Row>
            <Row>
                <Button size="small" block shape="round" type="outline" htmlType="submit">Filter</Button>
            </Row>
        </div>
    </Form>
);
export default FilterMenu;
