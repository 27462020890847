import React from "react";
import { createRoot } from "react-dom/client";
import './locales'

import "./assets/style/index.css";
import "./assets/style/App.less";
import "./assets/style/plugins/keenthemes-icons/font/ki.css";
import "./assets/style/plugins/flaticon/flaticon.css";
import "./assets/style/plugins/flaticon2/flaticon.css";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
