import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const Breadcrumbs = [
    {
        path: "/order",
        breadcrumbName: "Orders"
    }
];

const Columns = [
    {
        title: "Order",
        render: (_, record) => (
            <>
                <div>{ record?.branch_name }</div>
                <div>{ record?.ordered_at }</div>
                <div>{ record?.quantity }</div>
                { record?.order_fields.map(item => {
                    return <div key={ item.field_type.id }>{ item.field_type.name }</div>;
                }) }
                { record?.order_payments.map(item => {
                    return <div key={ item.payment_type.id }>{ item.payment_type.name }</div>;
                }) }
            </>
        )
    }
];
const Path = "/operations/order";
const Url = "/orders";
const ListUrl = `${Url}/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { Breadcrumbs, Columns, Path, Url, ListUrl, Router };

