import { API_URL, headers } from "@constant";
import { getStorage } from "@util";
const ROOT_STATE_STORAGE_KEY = "root";

const createHeaders = async () => {
    const headers_ = headers;
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    if (!storeData) return headers;
    const data = JSON.parse(storeData);
    if (data?.tokens?.access_token) {
        headers_["Authorization"] = "Bearer " + data?.tokens?.access_token;
    }
    return headers_;
};

const checkTokenExpire = (response) => {
    if (response.status == 401 || response.status === 422) {
        localStorage.removeItem("root")
        // window.location.href = "/auth/login";
    }
}

export const post = async (endpoint, credentials = null) => {
    try {
        const headers = await createHeaders();
        
        const response = await fetch(`${API_URL}${endpoint}`, {
            body: credentials ? JSON.stringify(credentials) : null,
            headers,
            method: "POST"
        });
        checkTokenExpire(response)
        const json = await response.json();
        return json;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const get = async (endpoint, params) => {
    try {
        const headers = await createHeaders();
        let endpont_with_query = endpoint
        if (params) {
            let filter = params.filter;
            delete params.filter
            let tableParams = new URLSearchParams(params)
            endpont_with_query = `${endpoint}?${tableParams}&filter=${JSON.stringify(filter)}`
        }
        const response = await fetch(`${API_URL}${endpont_with_query}`, {
            headers,
            method: "GET"
        });
        checkTokenExpire(response)
        const json = await response.json();
        return json;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const put = async (endpoint, credentials = null) => {
    try {
        const headers = await createHeaders();
        const response = await fetch(`${API_URL}${endpoint}`, {
            body: credentials ? JSON.stringify(credentials) : null,
            headers,
            method: "PUT"
        });
        checkTokenExpire(response)
        const json = await response.json();
        return json;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const delete_ = async (endpoint) => {
    try {
        const headers = await createHeaders();
        const response = await fetch(`${API_URL}${endpoint}`, {
            headers,
            method: "DELETE"
        });
        checkTokenExpire(response)
        const json = await response.json();
        return json;
    } catch (error) {
        console.error(error);
        return null;
    }
};
