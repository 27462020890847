
import { generateSidebarItems } from "@constant";
import { useLayout } from "@context";

import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

const SideBar = () => {
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(["dashboard"]);
    const [openKey, setOpenKey] = useState([]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { collapsed, setCollapsed } = useLayout();

    const handlePath = () => {
        const { pathname } = location;
        const paths = pathname.substring(1).split("/");
        const prefix = paths[0];
        setOpenKey([prefix]);
        const path = paths.pop();
        path && setSelectedKey([path]);
    };

    useEffect(() => {
        handlePath();
    }, [location.pathname]);

    const handleMenuClick = (item) => {
        const { key } = item;
        const sideBarItem = generateSidebarItems(t).find(ele => ele.children ? 
            ele.children?.find(elem => elem.key == key) : ele.key == key);
        const elem = sideBarItem.children ? sideBarItem.children.find(ele => ele.key == key ) : sideBarItem;
        const path = sideBarItem.children ? `/${sideBarItem.key}/${elem.key}` : `/${sideBarItem.key}`;
        navigate(path);
    };

    return (
        <Sider
            trigger={ null }
            collapsible
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={ collapsed }
            onCollapse={ setCollapsed }
            theme="light"  // Temayı "light" olarak ayarla

            style={ { background: "white" } }  // Özel bir stil ekleyerek arka plan rengini pembe yap
        >
            <div className="logo" />
            <Menu
                theme="light"  // Temayı "light" olarak ayarla
                key={ selectedKey }
                defaultSelectedKeys={ [selectedKey] }
                defaultOpenKeys={ [openKey] }
                mode="inline"
                items={ generateSidebarItems(t) }
                onClick={ handleMenuClick }
                style={ { background: "#fceef0", flexDirection: "column", display: "flex" } }
            />
        </Sider>
    );
};
export default SideBar;
