import { ArrowRightOutlined, HomeFilled, ReloadOutlined } from "@ant-design/icons";
import { usePageData } from "@context";
import { Breadcrumb as AntdBreadcrumb, Button, PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Breadcrumb = () => {
    
    const { pageTitle, pageDescription, pageBreadcrumbs, pageIcon, pageOnClick, setIsRefresh } = usePageData();
    const { t, i18n } = useTranslation();
    const Routes = () => (
        <AntdBreadcrumb>

            { pageBreadcrumbs.map((item, index) => 
                <AntdBreadcrumb.Item key={ index.toString() }>
                    { index === pageBreadcrumbs.length -1 ? 
                        <span className="text-muted">{ item.breadcrumbName }</span> :
                        <Link to={ item.path }>{ item.breadcrumbName }</Link> 
                    }
                </AntdBreadcrumb.Item>
            ) }
        </AntdBreadcrumb>
    );

    return (
        <PageHeader
            className="site-page-header"
            title={ pageTitle }
            avatar={ pageIcon && { icon:  pageIcon } }
            breadcrumb={ Routes() }
            subTitle={ pageDescription }
            extra={ [
                <Button
                    key="yourButton"
                    type="primary"
                    icon={ <ReloadOutlined /> }  // Add the PlusOutlined icon
                    onClick={ () => {
                        setIsRefresh((prevIsRefresh) => !prevIsRefresh);
                    } }
                    style={ { color: "black" } }
                >
                    { t("Refresh") }
                </Button>
            ] }
        />
    );
};

export default Breadcrumb;
