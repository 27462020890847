import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en";
import ja from "./ja";
import tr from "./tr";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "ja",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: en,
            tr: tr,
            ja: ja
        }
    });
export default i18n;
