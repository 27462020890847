import { formatNumber } from "@util";
import i18n from "locales";
import { Route, Routes } from "react-router-dom";
import { AddView } from "./add";

const generateBreadcrumbs = (t) => [
    {
        path: "/branch",
        breadcrumbName: t("Management Top")
    }
];
const colorMap = {
    over: "#6AC871",
    under: "#f2a3a2"
};
  
const generateColumns = (t) => [
    {
        title:  <div style={ { whiteSpace: "nowrap" } }>{ t("Restaurant") }</div>,
        render: (_, record, index) => (
            <div style={ { whiteSpace: "nowrap" } }>{ record?.company_name }</div>
        )
    },
    {
        title:  <div style={ { whiteSpace: "nowrap" } }>{ t("Branch") }</div>,
        render: (_, record, index) => (
            <div style={ { whiteSpace: "nowrap" } }>{ record?.name }</div>
        )
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Sales of the Day") }</div>,
        render: (_, record) => formatNumber(record?.daily_sale ?? 0)
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Total Sales of the Month") }</div>,
        render: (_, record) => formatNumber(record?.monthly_sale ?? 0)
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Target Sales") }</div>,
        render: (_, record) => formatNumber(record?.target_sale ?? 0)
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Achievement Rate") }</div>,
        render: (_, record) => {
            return {
                props: {
                    style: { background: colorMap[record?.achievement_rate_type] || "" }
                },
                children: `${record?.achievement_rate.toFixed(2)}%`
            };
        }
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Food Cost") }</div>,
        render: (_, record) => `${record?.food_cost.toFixed(2)}%`
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Labor Cost") }</div>,
        render: (_, record) => `${record?.labor_cost.toFixed(2)}%`
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("F/L") }</div>,
        render: (_, record) => {
            return {
                props: {
                    style: { background: colorMap[record?.f_l_rate_type] || ""  }
                },
                children: `${record?.f_l_rate.toFixed(2)}%`
            };
        }
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Sales Prediction of the Month") }</div>,
        render: (_, record) =>
            formatNumber(record?.monthly_sales_prediction ?? 0)
    },
    {
        title: <div style={ { whiteSpace: "nowrap" } }>{ t("Target Sales Prediction") }</div>,
        render: (_, record) => `${record?.target_sales_prediction.toFixed(2)}%`
    }
];

const Path = "/operations/branch";
const Url = "/expenses";
const ListUrl = `${Url}/table/`;

const Router = ({ onHide }) => (
    <Routes>
        <Route path="add" element={ <AddView onHide={ onHide }/> } />
        <Route path=":id/edit" element={ <AddView onHide={ onHide }/> } />
    </Routes>
);

export { generateBreadcrumbs, generateColumns, Path, Url, ListUrl, Router };
