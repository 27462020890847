import React, { createContext, useContext, useEffect, useState } from "react";

const PageDataContext = createContext({
    setPageTitle: (_title) => {},
    setPageBreadcrumbs: (_breadcrumbs) => {},
    setPageDescription: (_description) => {},
    setPageIcon: (_icon) => {},
    setIsRefresh: (_isRefresh) => {}
});

const PageDataProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState("");
    const [pageIcon, setPageIcon] = useState(undefined);
    const [pageDescription, setPageDescription] = useState("");
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false); // Başlangıç değeri false

    const value = {
        pageIcon,
        pageTitle,
        setPageTitle,
        setPageIcon,
        pageDescription,
        setPageDescription,
        pageBreadcrumbs,
        setPageBreadcrumbs,
        isRefresh,
        setIsRefresh
    };

    return <PageDataContext.Provider value={ value }>{ children }</PageDataContext.Provider>;
};

function usePageData() {
    return useContext(PageDataContext);
}

const PageTitle = ({ children, icon, description, breadcrumbs }) => {
    const { setPageTitle, setPageDescription, setPageBreadcrumbs, setPageIcon, setIsRefresh } = usePageData();

    useEffect(() => {
        if (children) {
            setPageTitle(children.toString());
        }
        if (icon) {
            setPageIcon(icon);
        }
        setIsRefresh(false); // isRefresh değerini false olarak ayarla
        return () => {
            setPageTitle("");
        };
    }, [children, icon, setIsRefresh]);

    useEffect(() => {
        if (icon) {
            setPageIcon(icon);
        }
        return () => {
            setPageIcon(undefined);
        };
    }, [icon, setPageIcon]);

    useEffect(() => {
        if (description) {
            setPageDescription(description);
        }
        return () => {
            setPageDescription("");
        };
    }, [description, setPageDescription]);

    useEffect(() => {
        if (breadcrumbs) {
            setPageBreadcrumbs(breadcrumbs);
        }
        return () => {
            setPageBreadcrumbs([]);
        };
    }, [breadcrumbs, setPageBreadcrumbs]);

    return <React.Fragment />;
};

const PageDescription = ({ children }) => {
    const { setPageDescription } = usePageData();

    useEffect(() => {
        if (children) {
            setPageDescription(children.toString());
        }
        return () => {
            setPageDescription("");
        };
    }, [children, setPageDescription]);

    return null;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
