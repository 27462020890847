import { get, post, put } from "@util";

import { Button, Drawer, Form, DatePicker, InputNumber, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [branch, setBranch] = useState(undefined)
    const [order, setOrder] = useState(undefined)


    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    const getBranch = async () => {
        const { result, success, message } = await get(`/branches/`);
        if (!success) {
            return;
        }
        let branches = result.map(branch => {return {label: branch.name, value: branch.id}})
        setBranch(branches)
    }

    const getOrder = async () => {
        const { result, success, message } = await get(`/orders/`);
        if (!success) {
            return;
        }
        let orders = result.map(order => {return {label: order.order_key, value: order.id}})
        setOrder(orders)
    }
   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getBranch();
        getOrder();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 500 }
            title= { primaryKey ? "Edit Reservation" : "Add Reservation" }
            visible={ visible }
            onClose={ onClose }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Branch"
                    name="branch_id"
                >
                    <Select options={branch} />
                </Form.Item>
                <Form.Item
                    label="Order"
                    name="order_id"
                >
                    <Select options={order} />
                </Form.Item>
                <Form.Item
                    label="Reservation Date"
                    name="reservation_date"
                >
                    <DatePicker 
                        style={{width: "100%"}}
                    />
                </Form.Item>
                <Form.Item
                    label="Number Of Customer"
                    name="number_of_customer"
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
