import { EmptyMessage, Spinner } from "@components";
import { useFilter, usePageData } from "@context";
import { handleTableChange } from "@helper";
import { formatNumber } from "@util";
import { Table, Typography } from "antd";
import i18n from "locales";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
import { useEffect, useState } from "react";

const classMap = {
    over: "greenClass",
    under: "pinkClass"
};

const DataTable = ({ useData,columns, initialQueryParams=undefined, setInitialQueryParams=undefined }) => {
    const { queryParams, setQueryParams } = useFilter();
    const queryParams_ = initialQueryParams ? initialQueryParams : queryParams;
    const setQueryParams_ = setInitialQueryParams ? setInitialQueryParams : setQueryParams;
    const { pageSize } = queryParams_;
    const { data, error, mutate } = useData(queryParams_);
    const [isFirst, setIsFirst] = useState(true);
    const { t, i18n } = useTranslation();
    const { isRefresh } = usePageData();


    useEffect(() => {
        if (data) {
            if(isFirst) return;
            mutate();
            console.log("mutate");
        }
        setIsFirst(false);
    }, [queryParams_, t, isRefresh]);

    if (!data && !error) {
        return <Spinner />;
    }
    
    const { result, total_result, total_count } = data;
    if(!result || result.length == 0){
        return <EmptyMessage />;
    }

    const pagination = {
        pageSize: pageSize,
        showLessItems:true,
        showTotal: (total, range) => 
            <span className="text-muted">
                <b>{ total }</b> 件中 <b>{ range[0] }</b> - <b>{ range[1] }</b> 件
            </span>,
        total: total_count
    };


      
    return (
        <Table
            columns={ columns }
            scroll={ { x: "calc(100vh - 4em)" } }
            style={ { width: "auto" } }
            showSorterTooltip={ { title: "Update order!" } }
            dataSource={ result }
            rowKey="id"
            onChange={ handleTableChange(setQueryParams_) }
            pagination={ pagination }
            summary={ ()=> {
    
        
        
                return (
                    <>
                        
                        <Table.Summary.Row className="summary-row">
                            <Table.Summary.Cell colSpan={ 2 }>{ i18n.t("Total") }</Table.Summary.Cell>
    
                            <Table.Summary.Cell>
                                <Text>{ formatNumber(total_result?.daily_sale ?? 0 ) }</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ formatNumber(total_result?.monthly_sale ?? 0 ) }</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ formatNumber(total_result?.target_sale ?? 0 ) }</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell  className={ classMap[total_result.achievement_rate_type] }>
                                <Text>{ total_result?.achievement_rate.toFixed(2) }%</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ total_result?.food_cost.toFixed(2) }%</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ total_result?.labor_cost.toFixed(2) }%</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className={ classMap[total_result.f_l_rate_type] }>
                                <Text>{ total_result?.f_l_rate.toFixed(2) }%</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ formatNumber(total_result?.monthly_sales_prediction ?? 0 ) }</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text>{ total_result?.target_sales_prediction.toFixed(2) }%</Text>
                            </Table.Summary.Cell>                            
                        </Table.Summary.Row>
                        
                    </>
                );
            } }
        />
    );
};
export default DataTable;
