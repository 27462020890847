import { initialFilter } from "@constant";

import React, { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export function useFilter() {
    return useContext(FilterContext);
}

export function FilterProvider({ children }) {
    const [queryParams, setQueryParams] = useState({
        date: "2023-12-6",
        sales_achievement_rate: 50,
        f_l_cost_rate: 50
    });
    const value = { queryParams, setQueryParams };
    return <FilterContext.Provider value={ value }>{ children }</FilterContext.Provider>;
}
