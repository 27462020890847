import { post, get } from "@util";

import { useCallback, useState } from "react";
import useSWR from "swr";

export const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);
    const toggle = useCallback(() => setState(state => !state), []);
    return [state, toggle];
};

export const usePersistedJsonState = (key, initialState) => {
    let rawStateFromStorage = undefined;
    if (typeof window !== "undefined") {
        rawStateFromStorage = localStorage.getItem(key);
    }
    const stateFromStorage =
      rawStateFromStorage && JSON.parse(rawStateFromStorage);
  
    const [value, setter] = useState(stateFromStorage || initialState);
  
    return [
        value,
        data => {
            localStorage.setItem(key, JSON.stringify(data));
            setter(data);
        }
    ];
};

export const usePersistedState = (key, initialState) => {
    let stateFromStorage = undefined;
    if (typeof window !== "undefined") {
        stateFromStorage = localStorage.getItem(key);
    }
    const [value, setter] = useState(stateFromStorage || initialState);
  
    return [
        value,
        data => {
            localStorage.setItem(key, data);
            setter(data);
        }
    ];
};

export const usePost = (ListUrl, queryParams) => {
    const fetcher = async () => await post(ListUrl, { ...queryParams });
    return useSWR(ListUrl, fetcher);
};

export const useGet = (ListUrl, queryParams) => {
    const fetcher = async () => await get(ListUrl, { ...queryParams });
    return useSWR(ListUrl, fetcher);
};
