import { usePersistedJsonState } from "@helper";
import { removeStorage } from "@util";
import i18n from "locales";

import { createContext, useContext } from "react";

const STORAGE_KEY = "root";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    i18n.changeLanguage("jp");
    const [authState, setAuthState] = usePersistedJsonState(
        STORAGE_KEY,
        undefined
    );

    const value = {
        authState,
        createSession: data => setAuthState(data),
        removeSession: async () => await removeStorage(STORAGE_KEY)
    };
    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}
