import { get, post, put } from "@util";

import { Button, Drawer, Form, Input, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [branch, setBranch] = useState(undefined)

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    const getBranch = async () => {
        const { result, success, message } = await get(`/branches/`);
        if (!success) {
            return;
        }
        let branches = result.map(branch => { return { label: branch.name, value: branch.id } })
        setBranch(branches)
    };


   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getBranch();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 500 }
            title= { primaryKey ? "Edit Menu" : "Add Menu" }
            visible={ visible }
            onClose={ onClose }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={ [{ required: true, message: "Menu name required!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Branch"
                    name="branch_id"
                    rules={[{ required: true, message: "Order branch required!" }]}
                >
                    <Select options={branch} />
                </Form.Item>
                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
