import {
    HomeOutlined,
    PieChartOutlined,
    ReadOutlined,
    ReconciliationOutlined,
    RestOutlined,
    ScheduleOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    SmallDashOutlined,
    TeamOutlined
} from "@ant-design/icons";




export const generateSidebarItems = (t) => [
    {
        label: t("Management Top"),
        key: "dashboard",
        style: { background: "#fceef0" },
        icon: <HomeOutlined style={ { color: "#fff", fontSize: "16px" } } />
    },
    {
        label: t("News"),
        key: "membership",
        icon: <TeamOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        style: { background: "#fceef0" }
        // children: [
        //     { label: t("Users"), key: "user", icon: <SmallDashOutlined /> },
        //     { label: t("Roles"), key: "role", icon: <SmallDashOutlined /> },
        //     { label: t("Staffs"), key: "staff", icon: <SmallDashOutlined /> }
        // ]
    },
    {
        label: t("Contact"),
        key: "operations",
        icon: <PieChartOutlined style={ { color: "#fff", fontSize: "20px" } } />,
        style: { background: "#fceef0", marginTop: "20vh" }
        // children: [
        //     { label: t("Branches"), key: "branch", icon: <ShopOutlined /> },
        //     { label: t("Menus"), key: "menu", icon: <ReadOutlined /> },
        //     { label: t("Products"), key: "product", icon: <RestOutlined /> },
        //     { label: t("Orders"), key: "order", icon: <ReconciliationOutlined /> },
        //     { label: t("Reservations"), key: "reservation", icon:<ScheduleOutlined /> },
        //     { label: t("Meals"), key: "meal", icon: <ShoppingCartOutlined /> }
        // ]
    }
];
  

  
