
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import { Footer, Spinner } from "@components";
import { useAuth } from "@context";
import { post } from "@util";
import { Button, Card, Col, Divider, Form, Input, Layout, Row, Space, notification } from "antd";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const Page = () => {
    const { createSession, authState } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState("ログイン");

    const checkAuthState = () => {
        console.log("authState", authState);
        if (authState) {
            const { tokens } = authState;
            if (tokens && tokens.access_token) {
                navigate("/dashboard");
            }
        }
    };

    useEffect(() => {
        checkAuthState();
    }, [authState]);

    const onSubmit = (data) => {
        setLoading(true);
        setButtonText("Please wait ...");
        setTimeout( async () => {
            try {
                const { account, tokens, success, message } = await post("/authenticate/login",data);
                if (!success) {
                    setLoading(false);
                    setButtonText("ログイン");
                    notification.error({
                        message: "注意",
                        description: message,
                        placement: "top"
                    });
                    return;
                }
                let user = account;
                setButtonText("Login Success!");
                await createSession({ tokens, user });
                navigate("/dashboard");
            } catch (e) {
                setButtonText("ログイン");
                setLoading(false);
                notification.error({
                    message: "注意",
                    description: e.toString(),
                    placement: "top"
                });
            }
        }, 100);
    };

    return (
        <Layout style={ { minHeight: "100vh" } }>
            <Content>
                <Row
                    justify="center"
                    align="middle"
                    style={ { minHeight: "calc(100vh - 80px)", padding: "0 30px" } }
                >
                    <Col align="middle">
                        <Card style={ { minHeight: "300px" } } align="left">
                            <h2 style={ { textAlign:"center", fontWeight: 600 } }>Sobani</h2>
                            <Divider plain={ false } />
                            <Form
                                onFinish={ onSubmit }
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="email"
                                    rules={ [{ required: true, message: "IDまたはEメールを入力してください。" }] }
                                >
                                    <Input 
                                        prefix={ <MailOutlined /> }
                                        placeholder="IDまたはメールアドレス" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={ [{ required: true, message: "パスワードを入力してください。" }] }
                                >
                                    <Input.Password 
                                        prefix={ <LockOutlined /> }
                                        placeholder="パスワード"/>
                                </Form.Item>
                                <Space size="middle" direction="vertical" style={ { width: "100%" } }>
                                    <Button block type="primary" 
                                        htmlType="submit" loading={ loading } style={ { color:"#c6c6c6" } }>
                                        { buttonText }
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                        { loading && <Spinner /> }
                    </Col>
                </Row>
            </Content>
            <Footer style={ { textAlign: "center", background: "#001529", color: "#FFF" } } />
        </Layout>
    );
};

export default Page;
