import { DataTable } from "@components";
import { PageTitle } from "@context";

import { Card } from "antd";

const PageContent = ({ icon, breadcrumbs, tableData, tableColumns, dataFilter, pageRoutes }) => (
    <>
        <PageTitle icon={ icon } breadcrumbs={ breadcrumbs }>{ breadcrumbs[breadcrumbs.length-1].breadcrumbName }</PageTitle>
        <Card bordered={ false }>
            { dataFilter }
            <DataTable useData={ tableData } columns={ tableColumns } />
        </Card>
        { pageRoutes }
    </>
);

export default PageContent;
