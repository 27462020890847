import { Route, Routes } from "react-router-dom";
import { MemberShipPage } from "./";
import { RolePage } from "./role";
import { StaffPage } from "./staff";
import { UserPage } from "./user";

const Router = () => (
    <Routes>
        <Route index element={ <MemberShipPage /> } />    
        <Route path="role/*" element={ <RolePage /> } />
        <Route path="user/*" element={ <UserPage /> } />
        <Route path="staff/*" element={ <StaffPage /> } />
    </Routes>  
);

export default Router;
