/* eslint-disable react-hooks/exhaustive-deps */
import {
    HomeOutlined,
    PieChartOutlined,
    ReadOutlined,
    ReconciliationOutlined,
    RestOutlined,
    ScheduleOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    SmallDashOutlined,
    TeamOutlined
} from "@ant-design/icons";
import { PageContent } from "@components";
import { initialFilter } from "@constant";
import { useFilter } from "@context";
import { Table } from "antd";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { Router, generateBreadcrumbs } from "../Constant";
import { useData, useTable  } from "../Helper";

const Page = () => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable(mutate);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        return () => setQueryParams({
            date: "2023-12-6",
            sales_achievement_rate: 50,
            f_l_cost_rate: 50
        });
    }, []);

    return (
        <PageContent 
            icon={ <HomeOutlined style={ { color:"#fff", fontSize: "16px" } }/> }
            breadcrumbs={ generateBreadcrumbs(t) } 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> } 
            pageRoutes={ <Router onHide={ onHide } /> }
            
        />

    );
};

export default Page;
