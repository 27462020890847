import { Error } from "@components";
import { useAuth } from "@context";
import { AppRenderer, LoginPage, ResetPassword } from "@routes";

import { Navigate, Route, Routes } from "react-router-dom";

const Default = () => {

    const { authState } = useAuth();
    const InitialRoute = () => (
        <Navigate to={ !authState?.tokens?.access_token ? "/auth/login" : "/dashboard" } />
    );
    
    return (
        <Routes>
            <Route path="/" element={ <InitialRoute /> } />
            <Route path="/auth/login" element={ <LoginPage /> } />
            <Route path="/reset_password/:reset_token" element={ <ResetPassword /> } />
            <Route path="/*" element={ <AppRenderer /> } /> 
            <Route path="*" element={ <Error /> } />
        </Routes>
    );
};

export default Default;
