
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { PageTitle } from "@context";
import { Card, Col, Row, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "./Helper";

const Page = () => {
    const navigate = useNavigate();
    return (
        <>
            <PageTitle breadcrumbs={ Breadcrumbs }>aaAccount Settings</PageTitle>
            <div className="site-card-wrapper">
                <Row gutter={ 16 }>
                    <Col span={ 8 }>
                        <Card title="Kullanıcılar" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/membership/user/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/membership/user") }/>
                        ] } >
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                    <Col span={ 8 }>
                        <Card title="Roller" bordered={ false } actions={ [
                            <PlusOutlined key="add" onClick={ () => navigate("/membership/role/add") }/>,
                            <ArrowRightOutlined key="show" onClick={ () => navigate("/membership/role") }/>
                        ] } >
                            <Statistic title="Active Users" value={ 112893 } />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
        
    );
};

export default Page;
