import { Tag } from "antd";

export const handleTableChange = (setQueryParams) => {
    return(pagination, filters, sorter) => {
        const pageNumber = pagination.current || 1;
        const pageSize = pagination.pageSize || 10;
        const sortField = sorter?.field || "id";
        const sortOrder = sorter?.order === "ascend" ? "asc" : "desc" || "desc";
        setQueryParams((prev) => ({ ...prev, sortField, sortOrder, pageNumber, pageSize }));
    };
    
};
export const formatResult = (datas) => {
    return datas?.map(item => ({ label: item.name, value: item.id }));
};


export const getColumns = (
    cols = [],
    renderAction = null
) => {
    const columns = [

    ];
    const actionColumns = [
        {
            dataIndex: "Active",
            title: "Durum",
            width: 100,
            render: (_, record) => (
                <Tag color={ record?.active === true ? "green" : "volcano" }>
                    { record?.active ? "Active": "Inactive" }
                </Tag>
            )
        },
        {
            title: "Action",
            dataIndex: "",
            width: 120,
            render: renderAction
        }
    ];
    return [...columns, ...cols];
};
