import { AuthProvider, FilterProvider, LayoutProvider, PageDataProvider } from "@context";

export const MasterProvider = ({ children }) =>  (
    <AuthProvider>
        <LayoutProvider>
            <PageDataProvider>
                <FilterProvider>{ children }</FilterProvider>
            </PageDataProvider>
        </LayoutProvider>
    </AuthProvider>
);
