import { Result } from "antd";

const Error = ({ 
    status="404", 
    title="404", 
    subTitle="Sorry, the page you have to connect not found on the server!" 
}) => (
    <Result status={ status } title={ title } subTitle={ subTitle }/>
);

export default Error;
