import { get, post, put } from "@util";

import { Button, DatePicker, Drawer, Form, Input, InputNumber, Select, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [branch, setBranch] = useState(undefined);
    const { Option } = Select;


    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            result.started_at = moment(result.started_at);
            result.quit_at = result.quit_at ? moment(result.quit_at): null;
            setData(result);
        }
    };

    const getBranch = async () => {
        const { result, success, message } = await get("/branches/");
        if (!success) {
            return;
        }
        let branches = result.map(branch => {return { label: branch.name, value: branch.id };});
        setBranch(branches);
    };
   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getBranch();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        console.log(values.started_at.format("YYYY-MM-DD"));
        values.started_at = values.started_at.format("YYYY-MM-DD");
        values.quit_at = values.quit_at ? values.quit_at.format("YYYY-MM-DD"): null;
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 500 }
            title= { primaryKey ? "Edit Staff" : "Add Staff" }
            visible={ visible }
            onClose={ onClose }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Branch"
                    name="branch_id"
                    rules={ [{ required: true, message: "Staff branch required!" }] }
                >
                    <Select options={ branch } />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={ [{ required: true, message: "Staff name required!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surname"
                    name="surname"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phone"
                    name="phone"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={ [{ required: true, message: "User email required!" }] }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Started At"
                    name="started_at"
                >
                    <DatePicker 
                        style={ { width: "100%" } }
                    />
                </Form.Item>
                <Form.Item
                    label="Quit At"
                    name="quit_at"
                >
                    <DatePicker 
                        style={ { width: "100%" } }
                    />
                </Form.Item>
                <Form.Item
                    label="Salary"
                    name="salary"
                >
                    <InputNumber style={ { width: "100%" } }/>
                </Form.Item>
                <Form.Item
                    label="Employment Type"
                    name="employment_type"
                >
                    <Select
                        defaultValue= "Full Time"
                        options={ [
                            {
                                value: 0,
                                label: "Full Time"
                            },
                            {
                                value: 1,
                                label: "Part Time"
                            }
                        ] }
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Staff Type"
                    name="staff_type"
                >
                    <Select
                        defaultValue= "Staff"
                        options={ [
                            {
                                value: 0,
                                label: "Staff"
                            },
                            {
                                value: 1,
                                label: "Head Of Staff"
                            },
                            {
                                value: 2,
                                label: "Manager"
                            }
                        ] }
                    >
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
