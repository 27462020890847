/* eslint-disable react-hooks/exhaustive-deps */
import { PageContent } from "@components";
import { initialFilter } from "@constant";
import { useFilter } from "@context";

import { useEffect } from "react";

import Filter from "./Filter";
import { Breadcrumbs, Router } from "../Constant";
import { useData, useTable  } from "../Helper";

const Page = () => {
    const { queryParams, setQueryParams } = useFilter();
    const { mutate } = useData(queryParams);
    const { columns, onHide } = useTable(mutate);

    useEffect(() => {
        return () => setQueryParams(initialFilter);
    }, []);

    return (
        <PageContent 
            breadcrumbs={ Breadcrumbs } 
            tableData={ useData } 
            tableColumns={ columns } 
            dataFilter={ <Filter /> } 
            pageRoutes={ <Router onHide={ onHide } /> }
        />
    );
};

export default Page;
