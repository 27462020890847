import { formatResult } from "@helper";
import { get, post, put } from "@util";

import { Button, Drawer, Form, Input, Select, notification } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Url } from "../Constant";

const AddView = ({ onHide }) => {
    const [form] = Form.useForm();
    const { id:primaryKey } = useParams();
    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [roles, setRoles] = useState([]);
    const [staff, setStaff] = useState(undefined);

    const getDataViaPrimaryKey = async () => {
        if (primaryKey) {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if(!success){
                notification.error({
                    description: message,
                    message: "注意",
                    placement: "top"
                });
                return;
            }
            setData(result);
        }
    };

    const getRoles = async () => {
        const { result, success, message } = await get("/roles/");
        if (!success) {
            return;
        }
        let roles = formatResult(result);
        setRoles(roles);
    };

    const getStaff = async () => {
        const { result, success, message } = await get("/staffs/");
        if (!success) {
            return;
        }
        let staffs = result.map(staff => {return { label: staff.name + " " + staff.surname, value: staff.id };});
        // let staffs = formatResult(result)
        setStaff(staffs);
    };

   
    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getRoles();
        getStaff();
    }, []);
    
    useEffect(() => {
        if(visible){
            form.setFieldsValue(data);
        }
    }, [form, data, visible]);

    const onClose = async () => {
        await setVisible(false);
        setTimeout(() => {
            onHide();
        }, 100);
    }; 

    const handleSave = async (values) => {
        const { success, message } = !primaryKey ? await post(`${Url}/add`, values) : 
            await put(`${Url}/${primaryKey}`, values);
        if(!success){
            notification.error({
                message: "注意",
                description: message,
                placement: "top"
            });
            return;
        }
        setData(undefined);
        onClose();
    };

    return (
        <Drawer
            width={ 500 }
            title= { primaryKey ? "Edit User" : "Add User" }
            visible={ visible }
            onClose={ onClose }
        >
            <Form
                form={ form }
                layout="vertical"
                requiredMark={ "Required" }
                onFinish={ handleSave }
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={ [{ required: true, message: "User email required!" }] }
                >
                    <Input />
                </Form.Item>
                { !primaryKey ? <Form.Item
                    label="Password"
                    name="password"
                >
                    <Input.Password />
                </Form.Item> : null }
                <Form.Item
                    label="Staff"
                    name="staff_id"
                    rules={ [{ required: true, message: "Order staff required!" }] }
                >
                    <Select options={ staff } />
                </Form.Item>
                <Form.Item
                    label="Roles"
                    name="roles"
                    rules={ [{ required: true, message: "Order staff required!" }] }
                >
                    <Select options={ roles } mode="multiple" allowClear style={ { width: "100%" } }/>
                </Form.Item>

                <Form.Item>
                    <Button block shape="round" type="primary" htmlType="submit">
                        SAVE
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
};
export default AddView;
