import { Spin } from "antd";

const Spinner = () => {
    return (
        <div className="loader-spinner">
            <Spin />
        </div>
    );
};

export default Spinner;
