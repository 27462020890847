
import { LockOutlined } from "@ant-design/icons";
import { Footer, Spinner } from "@components";
import { post } from "@util";

import { Button, Card, Col, Divider, Form, Input, Layout, Row, Space, notification } from "antd";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const { Content } = Layout;

const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState("パスワードを変更する");
    const { reset_token } = useParams();


    const onSubmit = async (data) => {
        console.log("reset_token", reset_token);
        if (data.password !== data.confirm_password) {
            alert("error");
        } else {
            setLoading(true);
            setButtonText("Please wait ...");
            data.reset_token = reset_token;
            const { success, message } = await post("/authenticate/reset-password/", data);
            if(!success){
                notification.error({
                    message: "注意",
                    description: message,
                    placement: "top"
                });
                return;
            }
            setLoading(false);
            notification.success({
                message: "パスワードが変更されました",
                description: "新しいパスワードで[Sobani]にログインしてください。",
                placement: "top"
            });
            setTimeout(() => {
                navigate("auth/login");
            }, 1000);
        }
    };

    return (
        <Layout style={ { minHeight: "100vh" } }>
            <Content>
                <Row
                    justify="center"
                    align="middle"
                    style={ { minHeight: "calc(100vh - 80px)", padding: "0 30px" } }
                >
                    <Col align="middle">
                        <Card style={ { minHeight: "300px" } } align="left">
                            <h2 style={ { textAlign:"center", fontWeight: 600 } }>パスワードを変更する 新しいパスワードを入力してください。</h2>
                            <Divider plain={ false } />
                            <Form
                                onFinish={ onSubmit }
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="password"
                                    rules={ [{ required: true, message: "Password required!" }] }
                                >
                                    <Input.Password 
                                        prefix={ <LockOutlined /> }
                                        placeholder="新しいパスワード"/>
                                </Form.Item>
                                <Form.Item
                                    name="confirm_password"
                                    rules={ [{ required: true, message: "Confirm Password required!" }] }
                                >
                                    <Input.Password 
                                        prefix={ <LockOutlined /> }
                                        placeholder="新しいパスワード(再確認)"/>
                                </Form.Item>
                                <Space size="middle" direction="vertical" style={ { width: "100%" } }>
                                    <Button block type="primary" 
                                        htmlType="submit" loading={ loading }>
                                        { buttonText }
                                    </Button>
                                </Space>
                            </Form>
                        </Card>
                        { loading && <Spinner /> }
                    </Col>
                </Row>
            </Content>
            <Footer style={ { textAlign: "center", background: "#001529", color: "#FFF" } } />
        </Layout>
    );
};

export default ResetPassword;
