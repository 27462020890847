import { Breadcrumb, Content, Footer, Header } from "@components";

import { Layout as AntLayout } from "antd";

const Layout = ({ children }) => {
    return (
        <AntLayout className="site-layout">
            <Header />
            <Breadcrumb />
            <Content>{ children }</Content>
            <Footer style={ { textAlign: "center"  } }/>
        </AntLayout>            
    );
};

export default Layout;
