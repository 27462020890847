import { Layout, SideBar } from "@components";

import { Layout as AntLayout, BackTop } from "antd";

const MasterLayout = ({ children }) => (
    <AntLayout style={ { minHeight: "100vh" } }>
        <SideBar />
        <Layout>{ children }</Layout>
        <BackTop />
    </AntLayout>
      
);

export default MasterLayout;
